export function useCsrfToken() {
  // the csrf-token is set in a meta tag in the development and production environments, but in the test environment
  // it is not setup so we return a blank string.  This allows end-to-end tests to work because rails also does not check
  // the CSRF token in the test env when the request is submitted.
  let csrfToken = document.querySelector("[name='csrf-token']")
  if (csrfToken) {
    return csrfToken.getAttribute('content')
  } else {
    return ''
  }
}
